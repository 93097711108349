import { reactive } from 'vue'
const { v4: uuidv4 } = require('uuid');
const CryptoJS = require("crypto-js");
const hmacSHA256 = require("crypto-js/hmac-sha256");
const Base64 = require("crypto-js/enc-base64");
import { useToast } from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-bootstrap.css';
const { fetch: originalFetch } = window;
const env = process.env.NODE_ENV;

function showTips(message) {
  useToast().open({
    message: message,
    type: 'default',
  });
}
async function postData(apiUrl, data, para = {}) {
  var paraInit = { token: false, sign: false };
  para = { ...paraInit, ...para };
  let timestamp = new Date().getTime();

  if (para.token) {
    if (this.userinfo.token == "") {
      console.log("no token sign in 1.");
      return;
    }
  }

  let sign = '';
  if (para.sign) {
    if (this.userinfo.token == "") {
      console.log("no token,sign in 2.");
      return;
    }
    sign = _sign(this.userinfo.token, timestamp, data);
  }

  const headers = {
    'x-isRelease': 'true',
    'x-platform': 'web',
    'x-version': this.version,
    'x-locale': window.navigator.language || window.navigator.browserLanguage,
    'x-timestamp': timestamp,
    'x-deviceId': getDeviceId(),
    'x-token': para.token ? this.userinfo.token : "",
    'x-sign': sign,
  };
  try {
    window.fetch = async (...args) => {
      let [resource, config] = args;
      let response = await originalFetch(resource, config);
      if (!response.ok) {
        // 401 error handling
        return Promise.reject(response);
      }
      return response;
    };

    const response = await fetch(this.baseApiUrl + apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        path: apiUrl,
        body: data
      }),
    }).catch((error) => {
      console.error(error.status);
      if (error.status == 401) {
        this.userinfo = {
          email: "",
          token: "",
        };
        localStorage.removeItem("userinfo");
        // this.$router.push('/signin')
        location.href = '/#signin';
      }
    });
    return await response.json();
  } catch {
    return {
      code: 500,
      msg: '',
      body: {}
    };
  }
}
function _sign(token, timestamp, data) {
  let httpData = '';
  let dataTP = {};
  if (data) {
    for (const k in data) {
      if (Object.hasOwnProperty.call(data, k)) {
        let v = data[k];
        if (v instanceof Array || v instanceof Object) {
          v = JSON.stringify(v);
        } else {
          v = v.toString();
        }
        if (v !== '' && k.substring(0, 1) !== '_') {
          dataTP[k] = v;
        }
      }
    }
    if (dataTP) {
      const sortedKeys = Object.keys(dataTP).sort();
      for (let f of sortedKeys) {
        httpData = `${httpData}${f}=${dataTP[f]}&`;
      }
      httpData = httpData.substring(0, httpData.length - 1);
    }
  }
  httpData = `${httpData}!!!token=${token}!!!timestamp=${timestamp}`;
  const hmacDigest = Base64.stringify(hmacSHA256(httpData, decode()));
  return hmacDigest;

}
function decode() {
  var cc = [12, 21, 3, 45, 5, 6, 6, 7, 17, 'cx', 'x4', 908, 12, 'er'];
  var bytes = CryptoJS.AES.decrypt('U2FsdGVkX1/Wt398VeuVzN8kDHVVyKiwpfcaUEZSb62yekwOVWBEjuQt02q/9wFk', cc.toString());
  return bytes.toString(CryptoJS.enc.Utf8);
}
function getDeviceId() {
  let xdeviceId = localStorage.getItem("xdeviceId");
  if (!xdeviceId) {
    xdeviceId = uuidv4();
    localStorage.setItem("xdeviceId", xdeviceId);
  }
  return xdeviceId;
}
function sysncUserInfo() {
  let userinfo = localStorage.getItem("userinfo");
  if (userinfo) {
    this.userinfo = JSON.parse(userinfo);
  }
  console.log(userinfo);
}
function unixToTime(val) {
  // return new Date(val).toLocaleString('zh-CN', {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  // });
  const date = new Date(val);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}/${month}/${day}`;
}
function milltimeToVideoTime(millSec) {
  let a = parseInt(millSec / 1000);
  if (a > 0) {
    let b = ""
    let h = parseInt(a / 3600),
      m = parseInt(a % 3600 / 60),
      s = parseInt(a % 3600 % 60);
    if (h > 0) {
      h = h < 10 ? '0' + h : h
      b += h + ":"
    }
    m = m < 10 ? '0' + m : m
    s = s < 10 ? '0' + s : s
    b += m + ":" + s
    return b;
  } else {
    return '00:00';
  }
}
function getIcon(type) {
  let icon;
  if (type == "spotify") {
    icon = './img/spotify.png';
  } else if (type == "onlineAppleMusic") {
    icon = './img/appleMusic.png';
  } else if (type == "online163Music") {
    icon = './img/net163Music.png';
  } else if (type == "localVideo") {
    icon = './img/video.png';
  } else if (type == "localAudio") {
    icon = './img/music.png';
  } else {
    icon = './img/onlineVideo.png';
  }
  return icon;
}
export const global = reactive({
  version: '1.0.0',
  baseApiUrl: env == "development" ? "http://10.0.0.178:9091/" : "https://play.xsonicaudio.com:9092/",//
  pageSize: 8,
  userinfo: { email: "", token: "" },
  postData,
  sysncUserInfo,
  showTips,
  unixToTime,
  milltimeToVideoTime,
  getIcon,
})