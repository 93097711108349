<!--
 * @Date: 2023-06-30 11:34:56
 * @LastEditors: teenagex
 * @LastEditTime: 2023-07-12 15:20:30
-->
<template>
  <!-- Navbar -->
  <header class="navbar navbar-expand-md d-print-none">
    <div class="container-xl">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu"
        aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        <a href=".">
          <img src="/img/logo.png" width="110" height="32" alt="Tabler" class="navbar-brand-image">
        </a>
      </h1>
      <div class="navbar-nav flex-row order-md-last">
        <div class="nav-item dropdown">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <span class="avatar avatar-sm" style="background-image: url('/img/avarat.jpg')"></span>
            <div class="d-none d-xl-block ps-2">
              <div>{{ formatAccount(global.userinfo.email) }}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a href="#" class="dropdown-item" @click.prevent="signoutBtn">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { global } from '../global.js'
export default {
  name: 'HomerBlock',
  data() {
    return {
      global: global,
    };
  }, methods: {
    signoutBtn: function () {
      localStorage.removeItem("userinfo");
      this.$router.push('/signin')
    },
    formatAccount(val) {
      let vals = val.split('@');
      return vals[0].toUpperCase();
    },
    themeModeChange() {
      var themeStorageKey = "tablerTheme";
      // var defaultTheme = "light";
      var currentTheme = localStorage.getItem(themeStorageKey);
      if (!currentTheme) {
        currentTheme = 'dark';
      } else {
        if (currentTheme == "dark") {
          currentTheme = 'light';
        } else {
          currentTheme = 'dark';
        }
      }
      localStorage.setItem(themeStorageKey, currentTheme);
      if (currentTheme === 'dark') {
        document.body.setAttribute("data-bs-theme", currentTheme);
      } else {
        document.body.removeAttribute("data-bs-theme");
      }
    }
  }
}
</script>
<style scoped>
.themeModeChange {
  cursor: pointer;
}
</style>