/*
 * @Date: 2023-06-07 11:41:52
 * @LastEditors: teenagex
 * @LastEditTime: 2023-07-12 15:29:37
 */
import { createRouter, createWebHashHistory } from 'vue-router';
// 1、引入组件
import Home from "../view/Home.vue"
import DestroyAccount from "../view/DestroyAccount.vue"
import Signin from "../view/Signin.vue"
/**
 * 2、配置路由映射关系
 */
const routes = [
  {
    path: '/',
    name: 'app',
    redirect: "/home"
  }, {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requireAuth: true,
    }
  }, {
    path: "/destroy_account",
    name: 'destroy_account',
    component: DestroyAccount,
    meta: {
      requireAuth: true,
    }
  }, {
    path: "/signin",
    name: 'signin',
    component: Signin
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    component: () => import("../view/NotFound.vue")
  }
]
const router = createRouter({
  // 指定模式
  /**
   * createWebHashHistory 带 # 号
   * createWebHistory 不带 # 号
   */
  history: createWebHashHistory(),
  // 下面这个 可以写成ES6的简写 routers
  routes: routes
})

export default router