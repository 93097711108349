<template>
  <router-view />
</template>
<script>
import { global } from './global.js'
export default {
  name: 'AppPage',
  created() {
    global.sysncUserInfo();
    if (global.userinfo.token == "") {
      this.$router.push('/signin')
    }
  },
}
</script>