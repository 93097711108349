<!--
 * @Date: 2023-06-07 11:19:56
 * @LastEditors: teenagex
 * @LastEditTime: 2023-07-12 15:49:42
-->
<template>
  <HeaderBlock msg="HeaderBlock" />
  <NavBlock msg="NavBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle"></div>
            <h2 class="page-title">

            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- total -->
    <div class="page-body">
      <div class="container-xl d-flex flex-column justify-content-center">
        <div class="empty">
          <h1> XSONIC </h1>
          <p class="empty-subtitle text-secondary">
            We are dedicated to designing revolutionary products that push the boundaries of what's possible in the
            world<br />
            of sound, unlocking endless creative possibilities for our users.
          </p>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../components/HeaderBlock.vue'
import FooterBlock from '../components/FooterBlock.vue'
import NavBlock from '../components/NavBlock.vue'
//
import { global } from '../global.js'
export default {
  name: 'HomePage',
  data() {
    return {
      global: global,
      total: {
        bookmarksCount: 0,
        loopsCount: 0,
        userCount: 0,
      }
    };
  },
  components: {
    HeaderBlock,
    FooterBlock,
    NavBlock
  },
  methods: {
    panelData: function () {
      global.postData("panel/total", {}, { token: true })
        .then(data => {
          if (data == null) {
            this.$router.push('/signin')
          } else {
            if (data.code == 200) {
              this.total = data.body;
            }
          }
        });
    }
  },
  created() {
    // this.panelData();
  },
}
</script>
<style scoped>
.h1 {
  font-size: 40px;
}
</style>