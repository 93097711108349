<template>
  <HeaderBlock msg="HeaderBlock" />
  <NavBlock msg="NavBlock" />
  <!-- title -->
  <div class="page-wrapper">
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle"></div>
            <h2 class="page-title">
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="page-body">
      <div class="container container-tight py-4">
        <h2 class="mb-3">Account cancellation request.</h2>
        <p class="text-secondary mb-4">
          After account cancellation, we will destroy all of your synchronized data within seven business days!
        </p>
        <ul class="list-unstyled space-y">
          <li class="row g-2">
            <span class="col-auto"><!-- Download SVG icon from http://tabler-icons.io/i/check -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x text-danger" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </span>
            <span class="col">
              <strong class="d-block">Delete Favorities</strong>
              <span class="d-block text-secondary">Delete all saved Favorities data you have.</span>
            </span>
          </li>
          <li class="row g-2">
            <span class="col-auto"><!-- Download SVG icon from http://tabler-icons.io/i/check -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x text-danger" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </span>
            <span class="col">
              <strong class="d-block">Delete Loops</strong>
              <span class="d-block text-secondary">Delete all saved Loops data you have.</span>
            </span>
          </li>
          <li class="row g-2">
            <span class="col-auto"><!-- Download SVG icon from http://tabler-icons.io/i/check -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x text-danger" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </span>
            <span class="col">
              <strong class="d-block">Delete Account</strong>
              <span class="d-block text-secondary">Delete the account information you have saved.</span>
            </span>
          </li>
        </ul>
        <div class="my-4">
          <a class="btn btn-danger w-100" data-bs-toggle="modal" data-bs-target="#modal-small">
            Delete all my data
          </a>
        </div>
        <p class="text-secondary">
        </p>
      </div>
      <div class="modal modal-blur fade" id="modal-small" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="modal-title">Are you sure?</div>
              <div>If you proceed, you will lose all your personal data.</div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link link-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="destroyAccount">Yes, delete all
                my data</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterBlock msg="FooterBlock" />
</template>

<script>
import HeaderBlock from '../components/HeaderBlock.vue'
import FooterBlock from '../components/FooterBlock.vue'
import NavBlock from '../components/NavBlock.vue'
import { global } from '../global.js'
export default {
  name: 'DestroyAccountPage',
  components: {
    HeaderBlock,
    FooterBlock,
    NavBlock,
  },
  data() {
    return {
      global: global,
    };
  },
  methods: {
    destroyAccount: function () {
      global.postData("user/destory_account", {}, { token: true, sign: true })
        .then(data => {
          global.showTips(data.msg);
          if (data.code == 200) {
            localStorage.removeItem("userinfo");
            this.$router.push('/signin')
          }
        });
    },
  },
}
</script>