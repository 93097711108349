<!--
 * @Date: 2023-06-30 15:51:36
 * @LastEditors: teenagex
 * @LastEditTime: 2023-07-12 15:22:29
-->
<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <div class="text-center mb-4 mt-4">
        <a href="." class="navbar-brand navbar-brand-autodark">
          <img src="/img/logo.png" width="110" height="32" alt="Tabler" class="navbar-brand-image">
        </a>
      </div>
      <div class="card card-md pb-4">
        <div class="card-body">
          <h2 class="h2 text-center mb-4">Login to your account</h2>
          <form action="./" method="get" autocomplete="off" novalidate="">
            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input type="email" class="form-control" placeholder="your@email.com" v-model="account" autocomplete="off">
            </div>
            <div class="mb-2">
              <label class="form-label">
                Password
              </label>
              <div class="input-group input-group-flat">
                <input type="password" class="form-control" placeholder="Your password" v-model="password"
                  autocomplete="off">
              </div>
            </div>
            <div class="form-footer">
              <button type="button" class="btn btn-primary w-100" @click="signinBtn">Sign in</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { global } from '../global.js'
export default {
  name: 'LoopsPage',
  data() {
    return {
      account: '',
      password: '',
    };
  },
  methods: {
    isEmail: function (input) {
      const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      return pattern.test(input);
    },
    signinBtn: function () {
      this.account = this.account.trim();
      if (this.account == '') {
        global.showTips('账号不能为空');
        return;
      }
      if (!this.isEmail(this.account)) {
        global.showTips('邮箱输入错误'); return;
      }
      if (this.password == '') {
        global.showTips('密码不能为空');
        return;
      }
      global.postData("user/signin", {
        'account': this.account,
        'password': this.password,
      })
        .then(data => {
          if (data.code == 200) {
            global.userinfo = {
              'email': data.body.email,
              'token': data.body.token,
            };
            localStorage.setItem("userinfo", JSON.stringify(global.userinfo));
            this.$router.push('/home')
          } else {
            global.showTips(data.msg);
          }
        });
    }
  },
  created() {
    global.sysncUserInfo();
  },
}
</script>
<style scoped>
.page {
  margin-top: 8%;
}
</style>